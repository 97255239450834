import { useGameStore } from "../../../store/store";
import { SpeakArgs } from "../../Audibilisers/SpeechSynthesis/SpeechSynthesis";
import { AnimalSvgs } from "../Animals/AnimalsSvgs";
import { LetterAnimation } from "../Animation/LetterAnimation";
import { mixHexColorsSubtractive } from "../ColourMixer/utils";
import { SVGMesh } from "../Shapes/Shapes3D";
import { ShapeSvgs } from "../Shapes/ShapesSVG";
import { Text } from "../Text/Text";

export type ColourSelectionItems = {
  title: string;
  colour: string;
};

type Props = {
  selectionItems: ColourSelectionItems[];
  speak: (args?: SpeakArgs) => void;
  voices: SpeechSynthesisVoice[];
  selectedColours: string[];
};
export const Renderer = ({ selectionItems, voices, selectedColours, speak }: Props) => {
  const selectedCategoryId = useGameStore((state) => state.selectedCategory);
  const isMixer = selectedCategoryId === "mixer";

  return (
    <>
      {[...selectionItems].reverse().map(
        (character, index) =>
          index === 0 && (
            <group
              key={index}
              onPointerDown={() =>
                speak({
                  text: character.title,
                  voice: voices.filter((voice) => voice.lang === "en-AU")[0]
                })
              }
            >
              <LetterAnimation key={index} disableRotation>
                {/** If we're in mixer mode, render a single block of geometry that fits the screen with colour of function "mixColours(selectedColours)" */}
                {isMixer ? (
                  <SVGMesh colour={mixHexColorsSubtractive(selectedColours)} shape="square" />
                ) : ShapeSvgs[character.title] || AnimalSvgs[character.title] ? (
                  <SVGMesh colour={character.colour} shape={character.title} />
                ) : (
                  <Text text={character.title} colour={selectedColours.find((s) => !!s) ?? "#FF0000"} />
                )}
              </LetterAnimation>
            </group>
          )
      )}
    </>
  );
};

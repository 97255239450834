import { MouseEventHandler } from "react";
import classes from "./Minimiser.module.scss";

type Props = {
  onClick: () => void;
  contents?: React.ReactElement[];
  styles?: React.CSSProperties[];
  size?: "small" | "medium" | "large";
};
export const Minimiser = ({ onClick, contents, size = "medium", styles }: Props) => {
  const iterations = contents ? contents : styles;

  const handleDoubleClick: MouseEventHandler<HTMLDivElement> = (event) => {
    // if (event.detail == 2) {
    onClick();
    // }
  };

  return (
    <div className={`${classes.minimiser} ${classes[size]}`} onClick={handleDoubleClick}>
      {iterations?.map((iteration, index) => (
        <div key={index} className={classes.box} style={styles?.[index]}>
          {contents?.[index] ?? null}
        </div>
      ))}
    </div>
  );
};

import { Mic } from "@mui/icons-material";
import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { useState } from "react";
import { Grid } from "../Game/components/Visualisers/Grid/Grid";
import classes from "./Storybook.module.scss";

export const ages = ["0-1", "1-2", "2-3", "3-4", "4-5", "5-6", "6+"];

export const Storybook = () => {
  const [age, setAge] = useState<string>("1-2");
  return (
    <Grid
      mainContent={
        <div className={classes.storybook}>
          <FormControl fullWidth>
            <InputLabel id="age">Age</InputLabel>
            <Select
              name="age"
              labelId="age"
              label={"age"}
              id="age"
              value={age}
              onChange={(e) => setAge(e.target.value)}
            >
              {ages.map((age) => (
                <MenuItem value={age} key={age}>
                  {age}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button variant="contained">Next</Button>
        </div>
      }
      topbar={<></>}
      bottombar={<></>}
    />
  );
};

type Props = {
  title: string;
  value: string;
  setFunction: (value: string) => void;
  selections?: string[];
};
const Inputs = ({ title, value, setFunction, selections }: Props) => {
  const isDropdown = !!selections;
  return (
    <div className={classes.storybook}>
      <FormControl fullWidth>
        {selections && (
          <Select
            name={title}
            labelId={title}
            label={title}
            id={title}
            value={title}
            onChange={(e) => setFunction(e.target.value)}
          >
            {isDropdown &&
              selections.map((selection) => (
                <MenuItem value={selection} key={selection}>
                  {selection}
                </MenuItem>
              ))}

            {!isDropdown && (
              <TextField
                id={title}
                label="Outlined"
                variant="outlined"
                value={value}
                onChange={(e) => setFunction(e.target.value)}
              />
            )}
          </Select>
        )}
      </FormControl>
      <Button variant="contained">Next</Button>
    </div>
  );
};

import { useState } from "react";
import { Minimiser } from "../Minimiser/Minimiser";
import { SelectionBox } from "./SelectionBox/SelectionBox";
import { colourData } from "../../../store/data";
import { useColourMinimiser } from "./data";

type Props = {
  selectedColours: string[];
  callback: (colour: { hex: string; name: string }) => void;
};
export const ColourPicker = ({ selectedColours, callback }: Props) => {
  const [isOpen, setIsOpen] = useState(true);
  const colourMinimiser = useColourMinimiser();

  return (
    <>
      <Minimiser onClick={() => setIsOpen(!isOpen)} contents={colourMinimiser} />

      {isOpen &&
        colourData.map((colour, i) => (
          <SelectionBox
            key={colour.hex}
            id={colour.hex}
            index={i}
            count={colourData.length}
            callback={() => callback({ hex: colour.hex, name: colour.name })}
            background={colour.hex}
            isSelected={selectedColours.includes(colour.hex)}
            border={"silver"}
            disablePastel
          />
        ))}
    </>
  );
};

import { AnimalSvgs } from "../components/Visualisers/Animals/AnimalsSvgs";
import { ShapeSvgs } from "../components/Visualisers/Shapes/ShapesSVG";

export const alphabetData = "A,B,C,D,E,F,G,H,I,J,K,L,M,N,O,P,Q,R,S,T,U,V,W,X,Y,Z".split(",");

export const colourData = [
  { hex: "#FF0000", name: "Red" },
  { hex: "#0000FF", name: "Blue" },
  { hex: "#FFFF00", name: "Yellow" },
  { hex: "#FFA500", name: "Orange" },
  { hex: "#008000", name: "Green" },
  { hex: "#800080", name: "Purple" },
  { hex: "#FFC0CB", name: "Pink" },
  { hex: "#000000", name: "Black" },
  { hex: "#800000", name: "Brown" },
  { hex: "#FFFFFF", name: "White" }
];

export const numericData = "0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20".split(",");

export const shapeData = Object.entries(ShapeSvgs);
export const animalData = Object.entries(AnimalSvgs);

export const mixerColourData = [
  { hex: "#FF0000", name: "Red" },
  { hex: "#0000FF", name: "Blue" },
  { hex: "#FFFF00", name: "Yellow" },
  { hex: "#008000", name: "Green" },
  { hex: "#FFA500", name: "Orange" },
  { hex: "#800080", name: "Purple" },
  { hex: "#FFC0CB", name: "Pink" },
  { hex: "#000000", name: "Black" },
  { hex: "#FFFFFF", name: "White" },
  { hex: "#800000", name: "Brown" },
  { hex: "#A52A2A", name: "Dark Brown" },
  { hex: "#00FFFF", name: "Cyan" },
  { hex: "#808080", name: "Gray" },
  { hex: "#FFD700", name: "Gold" },
  { hex: "#C0C0C0", name: "Silver" },
  { hex: "#B22222", name: "Firebrick" },
  { hex: "#F08080", name: "Light Red" },
  { hex: "#ADFF2F", name: "Lime Green" },
  { hex: "#87CEEB", name: "Sky Blue" },
  { hex: "#6A5ACD", name: "Slate Blue" },
  { hex: "#FF1493", name: "Hot Pink" },
  { hex: "#8B0000", name: "Dark Red" },
  { hex: "#FF4500", name: "Orange Red" },
  { hex: "#DA70D6", name: "Orchid" },
  { hex: "#F5DEB3", name: "Wheat" },
  { hex: "#4682B4", name: "Steel Blue" }
];

import spectral from "spectral.js";

/**
 * Simulates realistic subtractive color mixing using Spectral.js.
 *
 * @param hexColors Array of hex color strings (e.g., ["#0000FF", "#FFFF00"])
 * @returns A string representing the mixed color in hex format.
 */
export const mixHexColorsSubtractive = (hexColors: string[]): string => {
  if (hexColors.length === 0) {
    return "#FFFFFF"; // Default to white if no colors are provided
  }

  // Start mixing with the first color
  let mixedColor = hexColors[0];

  // Iteratively mix all the colors
  for (let i = 1; i < hexColors.length; i++) {
    mixedColor = spectral.mix(mixedColor, hexColors[i], 0.5);
  }

  return mixedColor;
};

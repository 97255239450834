import { useEffect } from "react";
import { EditorElement3D } from "../types";
import { GameStoreState, useGameStore } from "./store";
import { Matrix4 } from "three";
import hash from "hash-sum";
import { initialGameState } from "./state";

/**
 * We can subscribe to an element's attributes and get notified when they change.
 * This is so we limit re-renders within our app because we're dealing with fast-changing elements. (60fps)
 */
export const useSubscribeElementAttributes = (
  elementId: string,
  callback: (attributes: object) => void,
  /**
   * We can also specify an array of attributes to listen to.
   * This will be overridden if we specific a custom equality function.
   */
  customSpecificAttributes?: string[],
  /**
   * We also allow custom equality function so we can compare against specific attributes if need be.
   * This will override the customSpecificAttributes array.
   */
  customEqualityFn?: (a: EditorElement3D<any>, b: EditorElement3D<any>) => boolean
) => {
  useEffect(() => {
    const el = useGameStore.getState().elements[elementId];
    if (!el) return;

    const equalityFn = customEqualityFn || defaultEqualityFn(customSpecificAttributes);

    return useGameStore.subscribe(
      (state) => state.elements[elementId],
      (element: EditorElement3D<any>) => {
        callback(element.attributes);
      },
      { equalityFn }
    );
  }, []);
};

const getFilteredAttributes = (element: EditorElement3D<any>, attributesToFilter: string[]): Record<string, any> => {
  return attributesToFilter.reduce<Record<string, any>>((acc, attr) => {
    acc[attr] = element.attributes[attr];
    return acc;
  }, {});
};

const defaultEqualityFn =
  (customSpecificAttributes?: string[]) => (el1?: EditorElement3D<any>, el2?: EditorElement3D<any>) => {
    if (!el1 || !el2) return false;

    if (customSpecificAttributes) {
      const filteredAttributes1 = getFilteredAttributes(el1, customSpecificAttributes);
      const filteredAttributes2 = getFilteredAttributes(el2, customSpecificAttributes);

      return hash(filteredAttributes1) === hash(filteredAttributes2);
    }

    return hash(el1.attributes) === hash(el2.attributes);
  };

export const useSubscribeElementMatrix = (elementId: string, callback: (matrix: Matrix4) => void) => {
  const editorStore = useGameStore();
  useEffect(() => {
    const el = useGameStore.getState().elements[elementId];
    if (!el) return callback(new Matrix4());

    return useGameStore.subscribe(
      (state) => state.elements[elementId],
      (element: EditorElement3D<any>) => {
        callback(element.matrix);
      },
      {
        equalityFn: (el1?: EditorElement3D<any>, el2?: EditorElement3D<any>) => {
          return (el1 && el2 && !el1.matrix.equals(el2.matrix)) || false;
        }
      }
    );
  }, [callback, editorStore, elementId]);
};

/**
 * Returns an array of all the elements in the editor.
 * This won't re-render unless an element is added or removed.
 */
export const useSelectElements = () => {
  const elementIds = useGameStore(selectElementIds, idListEqualityFn);
  const elements = useGameStore.getState().elements;

  return elementIds.map((id) => elements[id]);
};

const selectElementIds = (state: GameStoreState) => Object.keys(state.elements);

const idListEqualityFn = (newIds: string[], oldIds: string[]) => {
  if (newIds.length !== oldIds.length) return false;

  const newIdsSet = new Set(newIds);
  for (let id of oldIds) {
    if (!newIdsSet.has(id)) return false;
  }

  return true;
};

export const useSelectedCategory = () => {
  return useGameStore((state) => initialGameState[state.selectedCategory]);
};

import { capitalize, lowerCase } from "lodash";
import { useState } from "react";
import { GameCanvas } from "../GameCanvas/GameCanvas";
import { NumberSounds } from "../components/Audibilisers/Numbers/numbers";
import { AlphabeticalSounds } from "../components/Audibilisers/Sounds/sounds";
import { useSpeechSynthesis } from "../components/Audibilisers/SpeechSynthesis/SpeechSynthesis";
import { KeyboardInteraction } from "../components/Tools/KeyboardInteraction/KeyboardInteraction";
import { Text } from "../components/Visualisers/Text/Text";
import { LetterAnimation } from "../components/Visualisers/Animation/LetterAnimation";
import { ColourPicker } from "../components/Visualisers/Selections/ColourPicker";
import { ShapeSvgs } from "../components/Visualisers/Shapes/ShapesSVG";
import { SVGMesh } from "../components/Visualisers/Shapes/Shapes3D";
import { ShapePicker } from "../components/Visualisers/Selections/ShapePicker";
import { AlphabetPicker } from "../components/Visualisers/Selections/AlphabetPicker";
import { NumericPicker } from "../components/Visualisers/Selections/NumericPicker";
import { Grid } from "../components/Visualisers/Grid/Grid";
import { EchoComponent } from "../components/Audibilisers/Echo/Echo";

export const LetterPhoneticsGame = () => {
  const { speak, voices } = useSpeechSynthesis();

  const [characters, setCharacters] = useState<{ character: string; colour: string }[]>([]);
  const [colour, setColour] = useState("#FF0000");

  const handleKeyDown = (event: KeyboardEvent) => {
    const letter = capitalize(event.key);
    if (!AlphabeticalSounds[letter] && !NumberSounds[letter]) return;

    setCharacters((state) => [...state, { character: letter, colour: colour }]);
    speak({
      text: letter,
      voice: voices.filter((voice) => voice.lang === "en-AU")[0]
    });
  };

  const handleColorChange = (c: { hex: string; name: string }) => {
    setColour(c.hex);
    setCharacters((state) =>
      state.map((char, i) => (i === state.length - 1 ? { colour: c.hex, character: char.character } : char))
    );
    speak({
      text: `${lowerCase(c.name)} `,
      voice: voices.filter((voice) => voice.lang === "en-AU")[0]
    });
    speak({
      text: characters.length ? lowerCase(characters[characters.length - 1].character) : "",
      voice: voices.filter((voice) => voice.lang === "en-AU")[0]
    });
  };

  const handleClick = (char: string, c: string) => {
    setColour(c);
    setCharacters((state) => [...state, { character: char, colour: char }]);

    speak({
      text: lowerCase(char),
      voice: voices.filter((voice) => voice.lang === "en-AU")[0]
    });
  };

  return (
    <Grid
      topbar={
        <>
          <EchoComponent />
          <NumericPicker callback={handleClick} />
        </>
      }
      leftSidebar={<ColourPicker selectedColours={[colour]} callback={handleColorChange} />}
      mainContent={
        <GameCanvas>
          <KeyboardInteraction onKeyDown={handleKeyDown} />
          {[...characters].reverse().map(
            (character, index) =>
              index === 0 && (
                <group
                  key={index}
                  onPointerDown={() =>
                    speak({
                      text: character.character,
                      voice: voices.filter((voice) => voice.lang === "en-AU")[0]
                    })
                  }
                >
                  <LetterAnimation key={index} disableRotation={!!ShapeSvgs[character.character]}>
                    {ShapeSvgs[character.character] ? (
                      <SVGMesh colour={character.colour} shape={character.character} />
                    ) : (
                      <Text text={character.character} colour={colour} />
                    )}
                  </LetterAnimation>
                </group>
              )
          )}
        </GameCanvas>
      }
      rightSidebar={<ShapePicker callback={handleClick} />}
      bottombar={<AlphabetPicker callback={handleClick} />}
    />
  );
};
